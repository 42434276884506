<template>
    <div>
        <headTitle :isMain="3"/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/02-5在线安全观察.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title">在线安全观察</div>
            <div class="mobile-head-explain-content">建造在线安全质量体系</div>
        </div>
        <div class="mobile-head-catalogue" >
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>解决方案</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>方案收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <div class="ware-title" style="margin-top:6.2rem">纸质化文档</div>
            <div class="ware-content1" style="margin-top:1rem">纸质填报无法随时上传照片证据，历史归档更无法随手拈来</div>
            <div class="ware-title" style="margin-top:4.8rem">流程繁琐</div>
            <div class="ware-content1" style="margin-top:1rem">填报过程繁琐，流程不统一，跟踪不及时</div>
            <div class="ware-title" style="margin-top:4.8rem">人工统计</div>
            <div class="ware-content1" style="margin-top:1rem;margin-bottom: 5rem;">每月必须有人专门进行报表统计，不能及时、准确的考核</div>

        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <img class="ware-img" style="margin-bottom:5rem" src="../../assets/image/example/02-6解决方案.png"/>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="purchase-box">
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-6即时填报.svg"/>
                    <div>
                        <div class="title1">及时填报</div>
                        <div class="title2">发现安全问题能够立即填报，省去手写纸质表单的时间</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-6拍照上报.svg"/>
                    <div>
                        <div class="title1">拍照上报</div>
                        <div class="title2">及时拍照上传图像数据，纸质填报无法及时关联相关证据</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-6流程透明.svg"/>
                    <div>
                        <div class="title1">流程透明</div>
                        <div class="title2">安全观察卡流转过程全程透明可追踪，及时了解流转进度</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-6月报.svg"/>
                    <div>
                        <div class="title1">月报自动生成</div>
                        <div class="title2">根据填报信息每月自动生成报表，节省人工统计时间提高工作效率</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-6分析.svg"/>
                    <div>
                        <div class="title1">分析优化</div>
                        <div class="title2">通过指标分析发现安全隐患，及时预防，促进行动改善</div>
                    </div>
                </div>
                <div class="card">
                    <img class="logo" src="../../assets/image/example/02-6闭环.svg"/>
                    <div>
                        <div class="title1">闭环管理</div>
                        <div class="title2">形成线上闭环管理，实时消息自动提醒，跟踪相关状态</div>
                    </div>
                </div>
            </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:false,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66.5rem;
    margin: auto;
    margin-top: 3rem;
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 100%;
    
}
.ware-img2{
    width: 100%;
    height: 63.3rem;
}
.purchase-box{
    width: 70rem;
    margin-left: -2rem;
    margin-bottom: 5rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    
    .card{
        width: 34.8rem;
        height: 15rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 2.5rem 0 0 1.2rem;
        }
        .title2{
            width: 25.2rem;
            height: 4.8rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 1.2rem 0 0 1.2rem;
        }
    }
}
</style>